import Vue from 'vue'
import Router from 'vue-router'

import zzz from '@/components/zzz/zzz.vue'
import zzz2 from '@/components/zzz2/zzz2.vue'

import homepage from '@/components/homepage/homepage.vue'

import companies from '@/components/companies/companies.vue'
import companiesmarketing from '@/components/companiesmarketing/companiesmarketing.vue'
import marketing from '@/components/marketing/marketing.vue'

import ordersdays from '@/components/ordersdays/ordersdays.vue'
import quotes from '@/components/quotes/quotes.vue'
import orders from '@/components/orders/orders.vue'
import invoices from '@/components/invoices/invoices.vue'
import incomeinvoices from '@/components/incomeinvoices/incomeinvoices.vue'

import checklists from '@/components/checklists/checklists.vue'
import contracts from '@/components/contracts/contracts.vue'
import audits from '@/components/audits/audits.vue'
import curricula from '@/components/curricula/curricula.vue'
import monitorings from '@/components/monitorings/monitorings.vue'

import externalaudit from '@/components/externalaudit/externalaudit.vue'
import externalsignature from '@/components/externalsignature/externalsignature.vue'

import qualifications from '@/components/qualifications/qualifications.vue'

import ordershours from '@/components/ordershours/ordershours.vue'
import orderscosts from '@/components/orderscosts/orderscosts.vue'
import communications from '@/components/communications/communications.vue'
import vehicles from '@/components/vehicles/vehicles.vue'


import settingscompanies from '@/components/settingscompanies/settingscompanies.vue'
import settingsaudits from '@/components/settingsaudits/settingsaudits.vue'
import settingsorders from '@/components/settingsorders/settingsorders.vue'
import settingsmails from '@/components/settingsmails/settingsmails.vue'
import settingserp from '@/components/settingserp/settingserp.vue'


import mails from '@/components/mails/mails.vue'
import mailcheat from '@/components/mailcheat/mailcheat.vue'

import banks from '@/components/banks/banks.vue'
import kpis from '@/components/kpis/kpis.vue'
import phonecalls from '@/components/phonecalls/phonecalls.vue'

import login from '@/components/auth/login.vue'
import resetmail from '@/components/auth/resetmail.vue'
import resetpassword from '@/components/auth/resetpassword.vue'


import axios from 'axios';

import store from '../store';

import mixin from '@/mixins/index'
Vue.mixin(mixin);

Vue.use(Router)


const router = new Router  ({
  routes:[{

    path: '/zzz',
    name: 'zzz',
    component: zzz,
    meta: {
      title: '',
      requiresAuth: false,
    },
  },{
    path: '/zzz2',
    name: 'zzz2',
    component: zzz2,
    meta: {
      title: '',
      requiresAuth: false,
    },
  },{
    path: '/homepage',
    name: 'homepage',
    component: homepage,
    meta: {
      title: '',
      requiresAuth: true,
    },
  },{
    path: '/companies',
    name: 'companies',
    component: companies,
    meta: {
      title: 'Aziende',
      requiresAuth: true,
    }
  },{
    path: '/companiesmarketing',
    name: 'companiesmarketing',
    component: companiesmarketing,
    meta: {
      title: 'Aziende marketing',
      requiresAuth: true,
    },
  },{
    path: '/marketing',
    name: 'marketing',
    component: marketing,
    meta: {
      title: 'Marketing',
      requiresAuth: true,
    },
  },{    
    path: '/quotes',
    name: 'quotes',
    component: quotes,
    meta: {
      title: 'Preventivi',
      requiresAuth: true,
    },
  },{
    path: '/orders',
    name: 'orders',
    component: orders,
    meta: {
      title: 'Commesse',
      requiresAuth: true,
    },
  },{
    path: '/ordersdays',
    name: 'ordersdays',
    component: ordersdays,
    meta: {
      title: 'Piano',
      requiresAuth: true,
    },
  },{
    path: '/invoices',
    name: 'invoices',
    component: invoices,
    meta: {
      title: 'Fatture',
      requiresAuth: true,
    },
  },{
    path: '/incomeinvoices',
    name: 'incomeinvoices',
    component: incomeinvoices,
    meta: {
      title: 'Fatture ingresso',
      requiresAuth: true,
    },
  },{
    path: '/checklists',
    name: 'checklists',
    component: checklists,
    meta: {
      title: 'Checklists',
      requiresAuth: true,
    },
  },{
    path: '/contracts',
    name: 'contracts',
    component: contracts,
    meta: {
      title: 'Contratti',
      requiresAuth: true,
    },
  },{
    path: '/audits',
    name: 'audits',
    component: audits,
    meta: {
      title: 'Audits',
      requiresAuth: true,
    },
  },{
    path: '/curricula',
    name: 'curricula',
    component: curricula,
    meta: {
      title: 'Curricula',
      requiresAuth: true,
    },
  },{
    path: '/externalaudit/:token',
    name: 'externalaudit',
    component: externalaudit,
    props: true,
    meta: {
      title: "Checklist",
      requiresAuth: false,
    },
  },{
    path: '/externalsignature/:token',
    name: 'externalsignature',
    component: externalsignature,
    props: true,
    meta: {
      title: "Signature",
      requiresAuth: false,
    },
  },{
    path: '/qualifications',
    name: 'qualifications',
    component: qualifications,
    meta: {
      title: 'Qualifiche',
      requiresAuth: true,
    },
  },{
    path: '/monitorings',
    name: 'monitorings',
    component: monitorings,
    meta: {
      title: "Monitoraggio",
      requiresAuth: true,
    },
  },{
    path: '/mails',
    name: 'mails',
    component: mails,
    meta: {
      title: 'Email',
      requiresAuth: true,
    },
  },{
    path: '/communications',
    name: 'communications',
    component: communications,
    meta: {
      title: 'Comunicazioni',
      requiresAuth: true,
    },
  },{
    path: '/ordershours',
    name: 'ordershours',
    component: ordershours,
    meta: {
      title: 'Ore',
      requiresAuth: true,
    },
  },{
    path: '/orderscosts',
    name: 'orderscosts',
    component: orderscosts,
    meta: {
      title: 'Spese',
      requiresAuth: true,
    },
  },{
    path: '/vehicles',
    name: 'vehicles',
    component: vehicles,
    meta: {
      title: 'Automezzi',
      requiresAuth: true,
    },





    
  },{
    path: '/settingscompanies',
    name: 'settingscompanies',
    component: settingscompanies,
    meta: {
      title: 'Impostazioni Aziende',
      requiresAuth: true,
    },
  },{
    path: '/settingsorders',
    name: 'settingsorders',
    component: settingsorders,
    meta: {
      title: 'Impostazioni commesse',
      requiresAuth: true,
    },
  },{
    path: '/settingsaudits',
    name: 'settingsaudits',
    component: settingsaudits,
    meta: {
      title: 'Impostazione audits',
      requiresAuth: true,
    },
  },{
    path: '/settingsmails',
    name: 'settingsmails',
    component: settingsmails,
    meta: {
      title: 'Impostazioni emails',
      requiresAuth: true,
    },
  },{
    path: '/settingserp',
    name: 'settingserp',
    component: settingserp,
    meta: {
      title: 'Impostazioni Utenze',
      requiresAuth: true,
    },









  },{
    path: '/banks',
    name: 'banks',
    component: banks,
    meta: {
      title: 'Banche',
      requiresAuth: true,
    },
  },{
    path: '/kpis',
    name: 'kpis',
    component: kpis,
    meta: {
      title: "Kpis",
      requiresAuth: true,
    },
  },{
    path: '/phonecalls',
    name: 'phonecalls',
    component: phonecalls,
    meta: {
      title: 'Chiamate',
      requiresAuth: true,
    },
  },{
    path: '/mailcheat',
    name: 'mailcheat',
    component: mailcheat,
    meta: {
      title: 'Mail cheat',
      requiresAuth: true,
    },
  },{
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: 'Login',
      requiresAuth: false,
    },
  },{
    path: '/resetmail',
    name: "resetmail",
    component: resetmail,
    meta: {
      title: 'Reset mail',
      requiresAuth: false,
    },
  },{
    path: '/resetpassword/:token',
    name: "resetpassword",
    component: resetpassword,
    props: true,
    meta: {
      title: 'Reset password',
      requiresAuth: false,
    },
  },{
    path: "*",
    redirect: "/homepage",
  }]
})

router.beforeEach((to, from, next) => {
  document.title = "Gestionale" + ( to.meta.title !== '' ? ' - ' + to.meta.title : '');
  if (to.meta.requiresAuth === true) {
    if (store.getters.getToken === null){
      store.commit("setNavbar", false);
      router.push('login');
    } else {
      let promise = axios.post("api/auths/routes", { token: store.getters.getToken });
      return promise.then((data) => {            
        if (data.data.result === true) {
          store.commit("setRoutes", data.data.data.routes)
          store.commit("setNavbar", true);
          next();
        } else {
          store.commit("setNavbar", false);
          router.push('login');
        }
        return(true)
      }).catch(() => {
        store.commit("setNavbar", false);
        router.push('login');
        return []
      })  
    }
  } else {
    next();
  }
})

export default router;