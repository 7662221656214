<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" size="lg" :title="'Scopo - ' + data.scope.id" @hidden="closeForm()" @close="closeForm()">
            <b-row>
                <b-col lg="12">
                    <b-btn variant="primary" size="sm" @click="data.scope.languages.push({id: null, scopeval: null})">Nuovo</b-btn>
                    <b-table no-border-collapse class="mt-2" :items="data.scope.languages" :fields="fields">
                        <template v-slot:cell(id)="row">
                        <b-form-select v-model="row.item.id" :options="data.languages.map(elem => ({ id: elem.id, val: elem.val, disabled: row.item.id > 0 ? (row.item.id === elem.id ? false : true) : ( data.scope.languages.map(x => x.id).includes(elem.id) ) }))" value-field="id" text-field="val"/>
                    </template>
                    <template v-slot:cell(scopeval)="row">
                        <b-form-textarea v-model="row.item.scopeval" rows="4"/>
                    </template>
                    <template v-slot:cell(delete)="row">
                        <b-btn v-if="row.item.id > 0" variant="danger" @click="data.scope.languages.splice(data.scope.languages.findIndex(x => x.id === row.item.id), 1)">Elimina</b-btn>
                    </template>
                    </b-table>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="12">
                    <b-form-group label="NACE">
                        <multiselect v-model="nacesSupport" :options="data.naces" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.scope.naces = updateCombo(nacesSupport)"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="12">
                    <b-form-group label="Esclusioni">
                        <multiselect v-model="standardsexclusionsSupport" :options="data.standardsexclusions" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.scope.standardsexclusions = updateCombo(standardsexclusionsSupport)"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="warning" class="mr-2" v-show="data.scope.id > 0" @click="data.scope.id = 0">Duplica</b-btn>
                    <b-btn variant="danger" class="mr-2" v-show="data.scope.id > 0" @click="deleteData()">Elimina</b-btn>
                    <b-btn variant="info" @click="importNaces()">Importa</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractscope",
    data() {
        return {
            show: false,
            data: null,
            fields: [{
                key: "id",
                label: "Lingua",
            },{
                key: "scopeval",
                label: "Scopo",
            },{
                key: "delete",
                label: "",
            }],
            nacesSupport: [],
            standardsexclusionsSupport: []
        }
    },
    methods: {
        loadData( contractsId, id ){
            this.openHttp("contracts/scope/", { contracts_id: contractsId, id: id }).then((result) => {
                this.nacesSupport = this.convertCombo(result.naces, result.scope.naces);
                this.standardsexclusionsSupport = this.convertCombo(result.standardsexclusions, result.scope.standardsexclusions);
                this.data = result;
                this.show = true;
            })
        },
        importNaces(){
            this.data.scope.naces = JSON.parse(JSON.stringify(this.data.nacescompanies));
            this.nacesSupport = this.convertCombo(this.data.naces, this.data.scope.naces);
        },
        saveData(){
            this.saveHttp("contracts/scope/save", this.data.scope).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("contracts/scope/delete", { id: this.data.scope.id, contracts_id: this.data.scope.contracts_id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>