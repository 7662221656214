<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Attestato - ' + data.certificate.id" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Scheda" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Inizio">
                  <b-form-input v-model="data.certificate.fromdate" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Fine">
                  <b-form-input v-model="data.certificate.todate" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Rilasciato da">
                  <b-form-input v-model="data.certificate.issuer"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Numero di Certificato">
                  <b-form-input v-model="data.certificate.code"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Durata (h)">
                  <b-form-input v-model="data.certificate.duration" type="number"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Attestato / Certificato">
                  <b-input-group v-for="item of data.certificate.languages" :key="item.id" :prepend="data.languages.find(x => x.id === item.id).val" class="mb-2">
                    <b-form-input v-model="item.degree"/>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Qualificato altro ente" v-show="data.certificate.standards.length > 0">
                  <b-form-select v-model="data.certificate.recognisedbyothercb" :options="recognisedbyothercbCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Valido 40 ore?" v-show="data.certificate.standards.length > 0">
                  <b-form-select v-model="data.certificate.qualify40" :options="qualify40Combo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Norme">
              <multiselect v-model="standardsSupport" :options="data.standards" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.certificate.standards = updateCombo(standardsSupport)"/>
            </b-form-group>
            <b-form-group label="Aree">
              <multiselect v-model="standardsareasSupport" :options="data.standardsareas" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.certificate.standardsareas = updateCombo(standardsareasSupport)"/>
            </b-form-group>
            <b-form-group label="IAFs">
              <multiselect v-model="nacesiafcodesSupport" :options="data.nacesiafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.certificate.nacesiafcodes = updateCombo(nacesiafcodesSupport)"/>
            </b-form-group>
            <hr>
            <b-form-group label="Controllo audits">
              <b-btn variant="primary" size="sm"  @click="loadAudits()">Audits</b-btn>
              <b-table no-border-collapse responsive show-empty class="mt-3" :items="logsData" :fields="fields.logs">
                <template v-slot:empty>Non ci sono audits</template>
              </b-table>
            </b-form-group>
          </b-tab>
          <b-tab :title="'Documenti - ' + data.documents.length" v-if="data.certificate.id">
            <curriculumdocument ref="document" @update="loadData(data.certificate.users_id, data.certificate.id)"/>
            <b-btn variant="primary" @click="loadDocument(0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive class="mt-3" :items="data.documents" :fields="fields.documents" @row-clicked="loadDocument($event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" v-if="row.item.datetime !== null" @click="$refs.document.downloadData(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-show="data.certificate.id > 0" class="mr-2" @click="loadData(data.certificate.users_id, data.certificate.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.certificate.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import curriculumdocument from './curriculumdocument.vue';

export default {
  name: "curriculumcertificate",
  components: { curriculumdocument },
  data() {
    return {
      show: false,
      data: null,
      standardsSupport: [],
      nacesiafcodesSupport: [],
      standardsareasSupport: [],
      qualify40Combo: [{
        id: false,
        val: "Non valido",
      },{
        id: true,
        val: "Valido 40 ore",
      }],
      recognisedbyothercbCombo: [{
        id: false,
        val: "No"
      },{
        id: true,
        val: "Riconosciuto altro ente"
      }],
      fields: {
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "datetime",
          label: "Aggiornamento",
          formatter: value => {
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "locked",
          label: "Bloccato",
          formatter: value => {
            return value === true ? "Bloccato" : "";
          },
        },{
          key: "id",
          label: "",
        }],
        logs: [{
          key: "company",
          label: "Azienda"
        },{
          key: "certificationbody",
          label: "Ente"
        },{
          key: "date",
          label: "Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "manday",
          label: "Giorni"
        },{
          key: "roles",
          label: "Ruolo"
        }]
      },
      logsData: [],
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassDocuments(item) {
      return item !== null && item.datetime === null ? 'table-danger' : '';
    },
    loadData( usersId, id ){
      this.openHttp("qualifications/curriculum/certificate/", { users_id: usersId, id: id }).then((result) => {
        this.standardsSupport = this.convertCombo(result.standards, result.certificate.standards);
        this.nacesiafcodesSupport = this.convertCombo(result.nacesiafcodes, result.certificate.nacesiafcodes);
        this.standardsareasSupport = this.convertCombo(result.standardsareas, result.certificate.standardsareas);
        this.data = result;
        this.show = true;
        this.loadAudits();
      })
    },
    loadDocument( id ){
      this.$refs.document.loadData("certificates", this.data.certificate.id, id);
    },
    getDatesInRange(startDate, endDate) {
      const date = new Date(startDate.getTime());
      const dates = [];
      while (date <= endDate) {
        dates.push([date.getDate(), date.getMonth() + 1, date.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-'));
        date.setDate(date.getDate() + 1);
      }
      return dates;
    },
    loadAudits(){
      const d1 = new Date(this.data.certificate.fromdate);
      const d2 = new Date(this.data.certificate.todate);
      this.openHttp("qualifications/curriculum/certificate/checklogs", { users_id: this.data.certificate.users_id, dates: this.getDatesInRange(d1, d2) }).then((result) => {
        this.logsData = result;
      })
    },
    saveData(){
      this.saveHttp("qualifications/curriculum/certificate/save", this.data.certificate).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("qualifications/curriculum/certificate/delete", { id: this.data.certificate.id, users_id: this.data.certificate.users_id }).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.data = null;
      this.show = false;
      if (reload === true) this.$emit("update");
    }
  }
}
</script>