<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" title="Lingua" @hidden="closeForm()" @close="closeForm()">
      <b-form-group label="Lingua">
        <b-form-select v-model="data.userslanguages.id" :options="data.languages" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Comprensione">
        <b-form-select v-model="data.userslanguages.languageslevelsread_id" :options="data.languageslevels" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Parlato">
        <b-form-select v-model="data.userslanguages.languageslevelsspeak_id" :options="data.languageslevels" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Scritto">
        <b-form-select v-model="data.userslanguages.languageslevelswrite_id" :options="data.languageslevels" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.userslanguages.old_languages_id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "curriculumlanguage",
  data() {
    return {
      show: false,
      data: null,
    }
  },
  methods: {
    loadData( usersId, id ){
      this.openHttp("qualifications/curriculum/language/", { users_id: usersId, id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("qualifications/curriculum/language/save", this.data.userslanguages).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("qualifications/curriculum/language/delete", { id: this.data.userslanguages.id, users_id: this.data.userslanguages.users_id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.data = null;
      this.show = false;
      this.$emit('update');      
    }
  }
}
</script>