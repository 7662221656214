<template>
  <div>
    <qualification ref="qualification" @update="loadQualifications()"/>
    <b-modal v-model="qualifications.showFilter" title="Filtra">
      <b-form-group label="Stato">
        <b-form-select v-model="qualifications.tempFilter.active" :options="addNullCombo([{id: true, val: 'Attivo'}, {id: false, val: 'Non Attivo'}])" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Personale">
        <b-form-select v-model="qualifications.tempFilter.users_id" :options="addNullCombo(qualifications.filtersMenu.users)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Ente">
        <b-form-select v-model="qualifications.tempFilter.certificationbodies_id" :options="addNullCombo(qualifications.filtersMenu.certificationbodies)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Norma">
        <b-form-select v-model="qualifications.tempFilter.standards_id" :options="addNullCombo(qualifications.filtersMenu.standards)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="Ruolo">
        <b-form-select v-model="qualifications.tempFilter.usersroles_id" :options="addNullCombo(qualifications.filtersMenu.usersroles)" value-field="id" text-field="val"/>
      </b-form-group>
      <b-form-group label="IAF">
        <b-form-select v-model="qualifications.tempFilter.nacesiafcodes_id" :options="addNullCombo(qualifications.filtersMenu.nacesiafcodes)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSetQualifications">Filtra</b-btn>
          <b-btn variant="warning" @click="filterResetQualifications">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadQualifications">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpenQualifications">Filtra</b-btn>
    <b-btn variant="success" @click="$refs.qualification.loadData(0)">Nuovo</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="qualifications.items.data" :fields="qualifications.fields" :busy="qualifications.isBusy" :no-local-sorting="true" :sort-by.sync="qualifications.search.sortby" :sort-desc.sync="qualifications.search.sortdesc" @sort-changed="sortingChangeQualifications" @row-clicked="$refs.qualification.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
      <template #cell(iafs)="row">
        <span  v-for="(item, index) of row.value"  :key="index">
          <b-btn size="sm" :variant="item.nacesno.length == 0 ? 'success' : 'danger'" v-b-tooltip.hover :title="item.nacesno.length == 0 ? 'Completo' : ('NO ' + item.nacesno.join(', '))" class="mr-1">{{ item.iafcode }}</b-btn>
        </span>
      </template>
    </b-table>
    <tablebottom :busy="qualifications.isBusy" :currentpage="qualifications.search.page" :total="qualifications.items.total" @updateCurrentPage="qualifications.search.page = $event" @loadData="loadQualifications"/>
  </div>
</template>

<script>
import qualification from './qualification.vue';

export default {
  name: "qualifications",
  components : { qualification },
  data() {
    return {
      qualifications: {
        isBusy: true,
        showFilter: false,
        search: {
          page: 1,
          filters: {
            active: true,
            users_id: null,
            certificationbodies_id: null,
            standards_id: null,
            usersroles_id: null,
            nacesiafcodes_id: null,
          },
          sortby: 'fullname',
          sortdesc: false,
        },
        tempFilter: {
          active: true,
          users_id: null,
          certificationbodies_id: null,
          standards_id: null,
          usersroles_id: null,
          nacesiafcodes_id: null
        },
        filtersMenu: {
          users:[],
          certificationbodies:[],
          standards: [],
          usersroles: [],
          nacesiafcodes: [],
        },
        fields: [{
          key: "fullname",
          label: "Nome",
          sortable: true,
        },{
          key: 'certificationbody',
          label: 'Ente',
          sortable: true,
        },{
          key: 'standard',
          label: 'Norma',
          sortable: true,
        },{
          key: 'usersrole',
          label: 'Ruolo',
          sortable: true,
        },{
          key: "iafs",
          label: "IAFs",
        },{
          key: 'fromdate',
          label: 'Da',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'todate',
          label: 'A',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        }],
        items: {
          total: 0,
          data: [],
        }
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  created(){
    this.loadQualifications();
  },
  methods: {
    loadQualifications(){
      this.qualifications.isBusy = true;
      this.openHttp("qualifications/qualifications/", this.qualifications.search ).then((result) => {
        this.qualifications.items = result;
        this.qualifications.isBusy = false;
      })
    },
    filterOpenQualifications(){
      this.openHttp("qualifications/qualifications/filters", null).then((result) => {
        this.qualifications.filtersMenu = result;
        this.qualifications.showFilter = true;
      })
    },
    filterSetQualifications(){
      this.qualifications.search.page = 1;
      this.qualifications.search.filters = this.qualifications.tempFilter;
      this.qualifications.showFilter = false;
      this.loadQualifications();
    },
    filterResetQualifications(){
      this.qualifications.tempFilter = {
        active: true,
        users_id: null,
        certificationbodies_id: null,
        standards_id: null,
        usersroles_id: null,
        nacesiafcodes_id: null,
      };
      this.filterSetQualifications();
    },
    sortingChangeQualifications(ctx) {
      this.qualifications.search.sortby = ctx.sortBy;
      this.qualifications.search.sortdesc = ctx.sortDesc;
      this.loadQualifications();
    }
  }
}
</script>