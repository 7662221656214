<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" :title="translate('non conformity') + ' - ' + data.nonconformity.id" scrollable @hidden="closeForm" @close="closeForm">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Apertura" active>
            <b-form-group label="Audit" v-if="data.audits.length > 1">
              <b-form-select v-model="data.nonconformity.audits_id" :options="data.audits" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Non conformità">
              <b-form-textarea v-model="data.nonconformity.nonconformity" rows="3"/>
            </b-form-group>
            <b-row>
              <b-col lg="8">
                <b-form-group label="Punti norma">
                  <b-form-checkbox-group v-model="data.nonconformity.standardspoints">
                    <b-form-group v-for="item in data.standardpoints.find(x => x.id === data.nonconformity.audits_id).standardpoints" :key="item.standard" :label="item.standard">
                      <b-form-checkbox v-for="item2 in item.points" :key="item2.id" :value="item2.id">{{ item2.val }}</b-form-checkbox>          
                    </b-form-group>
                </b-form-checkbox-group>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Fase">
                  <b-form-select v-model="data.nonconformity.auditsphases_id" :options="data.phases.find(x => x.id === data.nonconformity.audits_id).phases" value-field="id" text-field="val"/>
              </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Aperta da">
                  <b-form-select v-model="data.nonconformity.open_users_id" :options="data.users.find(x => x.id === data.nonconformity.audits_id).users" value-field="id" text-field="val"/>
              </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Tipologia">
                  <b-form-select v-model="data.nonconformity.auditsnonconformitiescertificationbodiestypes_id" :options="data.types.find(x => x.id === data.nonconformity.audits_id).types" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Accettata / rifiutata da">
                  <b-form-select v-model="data.nonconformity.approve_workers_id" :options="data.workers.find(x => x.id === data.nonconformity.audits_id).workers" value-field="id" text-field="val"/>
              </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Esito accettazione / rifiuto">
                  <b-form-select v-model="data.nonconformity.acceptdecision" :options="acceptdecisionCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Note accettazione / rifiuto">
              <b-form-textarea v-model="data.nonconformity.acceptnote" rows="3"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Gestione" :disabled="data.nonconformity.id == 0">
            <b-form-group label="Cause">
              <b-form-textarea v-model="data.nonconformity.cause" rows="3"/>
            </b-form-group>
            <b-form-group label="Trattamento">
              <b-form-textarea v-model="data.nonconformity.treatment" rows="3"/>
            </b-form-group>
            <b-form-group label="Azione correttiva">
              <b-form-textarea v-model="data.nonconformity.correctiveaction" rows="3"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Efficacia" :disabled="data.nonconformity.id == 0">
            <b-row>
              <b-col lg="4">
                <b-form-group label="Accettata / rifiutata da">
                  <b-form-select v-model="data.nonconformity.effectiveness_users_id" :options="data.users.find(x => x.id === data.nonconformity.audits_id).users" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="Esito accettazione / rifiuto">
                  <b-form-select v-model="data.nonconformity.effectivenessdecision" :options="effectivenessdecisionCombo" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Note vertifica efficacia">
              <b-form-textarea v-model="data.nonconformity.effectivenessnote" rows="2"/>
            </b-form-group>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="success" class="mr-2" @click="saveData">{{ translate('save') }}</b-btn>
          <b-btn variant="danger" v-show="data.nonconformity.id > 0" @click="deleteData">{{ translate('delete') }}</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "checklistnonconformity",
  data() {
    return {
      show: false,
      data: null,
      acceptdecisionCombo: [{
        id: true,
        val: 'Accettata'
      },{
        id: false,
        val: 'Non accettata'
      }],
      effectivenessdecisionCombo: [{
        id: true,
        val: 'Accettata'
      },{
        id: false,
        val: 'Non accettata'
      }]
    }
  },
  methods: {
    loadData( id, auditsId ){
      this.openHttp("checklists/nonconformity/", { id: id, audits_id: auditsId }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("checklists/nonconformity/save", this.data.nonconformity).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    deleteData( ){
      this.deleteHttp("checklists/nonconformity/delete", { id: this.data.nonconformity.id }).then((result) => {
        if ( result !== null ) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>