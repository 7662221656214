<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Qualifica - ' + data.qualification.id" @hidden="closeForm()" @close="closeForm()">
      <qualificationsignature ref="signature" @update="loadData(data.qualification.id)"/>
      <qualificationdocument ref="document" @update="loadData(data.qualification.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Form" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Persona">
                  <b-form-select v-model="data.qualification.users_id" :options="data.users" value-field="id" text-field="val" :disabled="data.qualification.id > 0"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Ente">
                  <b-form-select v-model="data.qualification.certificationbodies_id" :options="data.certificationbodies" value-field="id" text-field="val" :disabled="data.qualification.id > 0"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Norma">
                  <b-form-select v-model="data.qualification.standards_id" :options="data.standards" value-field="id" text-field="val" :disabled="data.qualification.id > 0"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Ruolo">
                  <b-form-select v-model="data.qualification.usersroles_id" :options="data.usersroles" value-field="id" text-field="val" :disabled="data.qualification.id > 0"/>
                </b-form-group>
              </b-col>
              <b-col lg="1">
                <b-form-group label="Da">
                  <b-form-input type="date" v-model="data.qualification.fromdate" :disabled="data.qualification.id > 0 && data.qualification.approve_users_id > 0"/>
                </b-form-group>
              </b-col>
              <b-col lg="1">
                <b-form-group label="A">
                  <b-form-input type="date" v-model="data.qualification.todate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Approvato">
                  <b-form-select v-model="data.qualification.approve_users_id" :options="data.approve_users" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table responsive :fields="fields.iafs" :items="data.naces" :sticky-header="(winHeight - 470)+ 'px'" v-if="data.qualification.id > 0 && data.usersroles.find(x => x.id === data.qualification.usersroles_id).neednace === true">
              <template v-slot:cell(iafcode)="row">
                <b-btn class="mr-1 mb-1" size="sm" variant="info">{{ row.value }}</b-btn>
                <div>
                  <b-btn size="sm" class="mr-1" variant="primary" v-show="row.item.naces.map(x => x.id).filter(x => data.qualification.naces.includes(x)).length === 0" @click="data.qualification.naces = data.qualification.naces.concat(row.item.naces.map(x => x.id))">Aggiungi</b-btn>
                  <b-btn size="sm" variant="warning" v-show="row.item.naces.map(x => x.id).filter(x => !data.qualification.naces.includes(x)).length === 0" @click="data.qualification.naces = data.qualification.naces.filter(y => !row.item.naces.map(x => x.id).includes(y))">Rimuovi</b-btn>
                </div>
              </template>
              <template v-slot:cell(naces)="row">
                <b-btn v-for="(item, index) of row.value" :key="index" class="mr-1 mb-1" size="sm" :variant="data.qualification.naces.includes(item.id) ? 'success':'secondary'" @click="addRemoveNace(item.id)">{{ item.val }}</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Storia" v-if="data.qualification.id > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive :items="history" :fields="fields.history">
              <template v-slot:cell(iafs)="row">
                <b-btn v-for="(item, index) of row.value" :key="index" class="mr-1 mb-1" size="sm" v-b-tooltip.hover.left="item.nacesno.length === 0 ? null : ('NO: ' + item.nacesno.join(', '))" :variant="item.nacesno.length === 0 ? 'success': 'warning'">{{ item.iafcode }}</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Audit non coperti" v-if="data.qualification.id > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive :items="missing" :fields="fields.missing" />
          </b-tab>
          <b-tab title="Aree tecniche" v-if="data.technicalareas.length > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" responsive :items="data.technicalareas" :fields="fields.technicalareas">
              <template v-slot:cell(technicalarea)="row">
                <div>{{ row.value }}</div>
                <b-btn variant="primary" size="sm" @click="addNaces(row.item.naces)">Aggiungi</b-btn>
              </template>
              <template v-slot:cell(details)="row">
                <b-form-group :label="'Certificati ' + row.item.certificates.map(x => x.mandays).reduce((acc, curV) => acc + curV, 0) + ' Mandays'" v-if="row.item.certificates.length > 0">
                  <b-table no-border-collapse responsive :items="row.item.certificates" :fields="fields.experiences"/>
                </b-form-group>
                <b-form-group :label="'Educazione scolastica ' + row.item.educations.map(x => x.years).reduce((acc, curV) => acc + Math.round(curV * 100) / 100, 0) + ' Years'" v-if="row.item.educations.length > 0">
                  <b-table no-border-collapse responsive :items="row.item.educations" :fields="fields.experiences"/>
                </b-form-group>
                <b-form-group :label="'Esperienza lavorativa ' + row.item.experiences.map(x => x.years).reduce((acc, curV) => acc + Math.round(curV * 100) / 100, 0) + ' Years'" v-if="row.item.experiences.length > 0">
                  <b-table no-border-collapse responsive :items="row.item.experiences" :fields="fields.experiences"/>
                </b-form-group>
                <b-form-group :label="'Consulenze ' + row.item.consultancies.map(x => x.mandays).reduce((acc, curV) => acc + curV, 0) + ' Mandays ' + row.item.consultancies.map(x => x.years).reduce((acc, curV) => acc + Math.round(curV * 100) / 100, 0) + ' Years'" v-if="row.item.consultancies.length > 0">
                  <b-table no-border-collapse responsive :items="row.item.consultancies" :fields="fields.experiences"/>
                </b-form-group>
                <b-form-group :label="'Logs ' + row.item.auditlogs.map(x => x.mandays).reduce((acc, curV) => acc + curV, 0) + ' Mandays'" v-if="row.item.auditlogs.length > 0">
                  <b-table no-border-collapse responsive :items="row.item.auditlogs" :fields="fields.experiences"/>
                </b-form-group>
              </template>
            </b-table>
          </b-tab>
          <b-tab :title="'Documenti ' + data.documents.length" v-if="data.qualification.id > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 390) + 'px'" :tbody-tr-class="rowClassDocuments" responsive class="mt-3" :items="data.documents" :fields="fields.documents" @row-clicked="$refs.document.loadData('users', data.qualification.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" v-if="row.item.datetime !== null" @click="$refs.document.downloadData(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-show="data.qualification.id > 0" class="mr-2" @click="loadData(data.qualification.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="warning" v-show="data.qualification.id > 0" class="mr-2" @click="data.qualification.id = 0; data.qualification.approve_users_id = null">Duplica</b-btn>
          <b-btn variant="danger" v-show="data.qualification.id > 0" @click="deleteData()">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import qualificationsignature from './qualificationcomponents/qualificationsignature.vue';
import qualificationdocument from './qualificationcomponents/qualificationdocument.vue';

export default {
  name: "qualification",
  components: { qualificationsignature, qualificationdocument },
  data() {
    return {
      show: false,
      data: null,
      history: [],
      missing: [],
      fields: {
        iafs: [{
          key: 'iafcode',
          label: "IAF",
          sortable: true,
        },{
          key: 'naces',
          label: 'NACE'
        }],
        history: [{
          key: 'fromdate',
          label: "Da",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          thStyle: { width: "10%" },
        },{
          key: 'todate',
          label: "A",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          thStyle: { width: "10%" },
        },{
          key: 'usersrole',
          label: "Ruolo",
          sortable: true,
          thStyle: { width: "15%" },
        },{
          key: "iafs",
          label: "IAFs",
          sortable: true,
          thStyle: { width: "65%" },
        }],
        missing: [{
          key: 'id',
          label: "#",
        },{
          key: 'company',
          label: "Azienda",
          sortable: true,
        },{
          key: 'date',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "naces",
          label: "NACEs KO",
        }],
        technicalareas: [{
          key: 'fromdate',
          label: "Area",
          sortable: true,
          thStyle: { width: "20%" },
        },{
          key: 'details',
          label: "Dettagli",
          thStyle: { width: "80%" }
        }],
        experiences: [{
          key: 'from',
          label: "Da",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          thStyle: { width: "10%" },
        },{
          key: 'to',
          label: "A",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          thStyle: { width: "10%" },
        },{
          key: 'experience',
          label: "Esperienza",
          thStyle: { width: "70%" }
        },{
          key: 'years',
          label: "Anni",
          thStyle: { width: "5%" }
        },{
          key: 'mandays',
          label: "GG",
          thStyle: { width: "5%" }
        }],
        documents: [{
          key: 'filename',
          label: 'File',
          sortable: true,
        },{
          key: 'jsonkey',
          label: 'Dettagli',
        },{
          key: 'datetime',
          label: 'Data - ora',
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-') + " " + [d.getHours(), d.getMinutes(), d.getSeconds()].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
          }
        },{
          key: "id",
          label: "",
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassDocuments(item) {
      return item !== null && item.datetime === null ? "table-danger" : "";
    },
    addRemoveNace(naceId){
      if (this.data.qualification.naces.includes(naceId)){
        this.data.qualification.naces.splice(this.data.qualification.naces.indexOf(naceId),1);
      } else {
        this.data.qualification.naces.push(naceId);
      }
    },
    addNaces(naces){
      this.data.qualification.naces = this.data.qualification.naces.concat(naces.filter(x => !this.data.qualification.naces.includes(x)))
    },
    groupExperiences(arr){
      var result = [];
      return arr.reduce(function(res, value) {
        if (!res[value.typeexperience]) {
          res[value.typeexperience] = { typeexperience: value.typeexperience, daysexperience: 0 };
          result.push(res[value.typeexperience])
        }
        res[value.typeexperience].daysexperience += value.daysexperience;
        return result;
      }, {});
    },
    loadData(id){
      this.openHttp("qualifications/qualification/", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
        this.loadHistory();
        this.loadMissing();
      })
    },
    loadHistory(){
      this.openHttp("qualifications/qualification/history", { users_id: this.data.qualification.users_id, certificationbodies_id: this.data.qualification.certificationbodies_id, standards_id: this.data.qualification.standards_id }).then((result) => {
        this.history = result;
      })
    },
    loadMissing(){
      this.openHttp("qualifications/qualification/missing", { users_id: this.data.qualification.users_id, certificationbodies_id: this.data.qualification.certificationbodies_id, standards_id: this.data.qualification.standards_id }).then((result) => {
        this.missing = result;
      })
    },
    saveData(){
      this.saveHttp("qualifications/qualification/save", this.data.qualification).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("qualifications/qualification/delete", { id: this.data.qualification.id }).then((result) => {
        if (result !== null) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    },
  },
}
</script>