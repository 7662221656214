<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Consulenza - ' + data.consultancy.id" @hidden="closeForm()" @close="closeForm()">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Form" active>
            <b-row>
              <b-col lg="2">
                <b-form-group label="Inizio">
                  <b-form-input v-model="data.consultancy.fromdate" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Fine">
                  <b-form-input v-model="data.consultancy.todate" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Giornate">
                  <b-input-group>
                    <b-form-input v-model="data.consultancy.daysdone" type="number"/>
                    <b-input-group-append>
                      <b-button variant="info" @click="calculateMandays()">Calcola</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Società di consulenza (per cui si lavora)">
                  <b-form-input v-model="data.consultancy.contractor"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="10">
                <b-form-group>
                  <company ref="company"/>
                  <label>Azienda</label><b-link v-show="data.consultancy.companies_id > 0" @click="$refs.company.loadData(data.consultancy.companies_id)"> - Apri</b-link>
                  <b-form-input v-model="data.consultancy.company"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="ID Gestionale">
                  <b-form-input v-model="data.consultancy.companies_id" type="number"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Settore">
              <b-input-group class="mt-2" v-for="item of data.consultancy.languages" :key="item.id" :prepend="data.languages.find(x => x.id === item.id).val">
                <b-form-textarea rows="2" max-rows="10" v-model="item.sector"/>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Aree">
              <multiselect v-model="standardsareasSupport" :options="data.standardsareas" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.consultancy.standardsareas = updateCombo(standardsareasSupport)"/>
            </b-form-group>
            <b-form-group label="Ruolo e Responsabilità">
              <b-input-group class="mt-2" v-for="item of data.consultancy.languages" :key="item.id" :prepend="data.languages.find(x => x.id === item.id).val">
                <b-form-textarea v-model="item.responsibilities" rows="2" max-rows="10"/>
              </b-input-group>
            </b-form-group>
            <b-form-group label="IAFs">
              <multiselect v-model="nacesiafcodesSupport" :options="data.nacesiafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.consultancy.nacesiafcodes = updateCombo(nacesiafcodesSupport)"/>
            </b-form-group>
          </b-tab>
          <b-tab :title="'Documents - ' + data.documents.length"  v-if="data.consultancy.id > 0">
            <curriculumdocument ref="document" @update="loadData(data.consultancy.users_id, data.consultancy.id)"/>
            <b-btn variant="primary" size="sm" @click="loadDocument(0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive class="mt-3" :items="data.documents" :fields="fields.documents" @row-clicked="loadDocument($event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" @click="$refs.document.downloadData(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-show="data.consultancy.id > 0" class="mr-2" @click="loadData(data.consultancy.users_id, data.consultancy.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.consultancy.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import curriculumdocument from './curriculumdocument.vue';

export default {
  name: "curriculumconsultancy",
  components: { curriculumdocument },
  data() {
    return {
      show: false,
      data: null,
      standardsareasSupport: [],
      nacesiafcodesSupport: [],
      fields: {
        naces: [{
          key: "ea",
          label: "IAF",
          sortable: true
        },{
          key: "description",
          label: "Descrizione"
        },{
          key: "naces",
          label: "NACE"
        }],
        nacesrisks: [{
          key: "area",
          label: "Area"
        },{
          key: "risks",
          label: "Rischi"
        }],
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "datetime",
          label: "Aggiornamento",
          formatter: value => {
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "locked",
          label: "Bloccato",
          formatter: value => {
            return value === true ? "Bloccato" : "";
          },
        },{
          key: "id",
          label: "",
        }]
      }
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassDocuments(item) {
      return item !== null && item.check === false ? 'table-danger' : '';
    },
    addRemoveNaceRisk(naceriskId){
      if (this.data.consultancy.nacesrisks.includes(naceriskId)){
        this.data.consultancy.nacesrisks.splice(this.data.consultancy.nacesrisks.indexOf(naceriskId),1);
      } else {
        this.data.consultancy.nacesrisks.push(naceriskId)
      }
    },
    loadData(usersId, id){
      this.openHttp("qualifications/curriculum/consultancy/", { id: id, users_id: usersId }).then((result) => {
        this.standardsareasSupport = this.convertCombo(result.standardsareas, result.consultancy.standardsareas);
        this.nacesiafcodesSupport = this.convertCombo(result.nacesiafcodes, result.consultancy.nacesiafcodes);
        this.data = result;
        this.show = true;
      })
    },
    calculateMandays(){
      const date_from = new Date(this.data.consultancy.fromdate);
      const date_to = new Date(this.data.consultancy.todate === null ? this.data.consultancy.lastupdatecv : this.data.consultancy.todate);
      let difference = Math.ceil((date_to.getTime() - date_from.getTime()) / (1000 * 3600 * 24));
      let TempDays = 0;
      if (difference > 365){
        TempDays = 5 + Math.ceil((difference - 365) / 365 * 5);
      } else {
        if (this.data.consultancy.standardsareas.length > 2){
          TempDays = 5;
        } else {
          TempDays = 3;
        }
      }
      this.data.consultancy.daysdone = TempDays;
    },
    loadDocument(fileName){
      this.$refs.document.loadData("consultancies", this.data.consultancy.id, fileName);
    },
    saveData(){
      this.saveHttp("qualifications/curriculum/consultancy/save", this.data.consultancy).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("qualifications/curriculum/consultancy/delete", { id: this.data.consultancy.id, users_id: this.data.consultancy.users_id }).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    closeForm( reload = false ){
      this.data = null;
      this.show = false;
      if (reload === true) this.$emit("update");
    }
  }
}
</script>