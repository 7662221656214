<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" :title="'Nomina - ' + data.certificationbody.id" @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="6">
          <b-form-group label="Ente">
            <b-form-select v-model="data.certificationbody.certificationbodies_id" :options="data.certificationbodies" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Approvato da">
            <b-form-select v-model="data.certificationbody.users_id2" :options="addNullCombo(data.users, 'Nessuno/a')" value-field="id" text-field="val"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4">
          <b-form-group label="Data inizio">
            <b-form-input v-model="data.certificationbody.date_begin" type="date"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Data aggiornamento">
            <b-form-input v-model="data.certificationbody.date_update" type="date"/>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Data fine">
            <b-form-input v-model="data.certificationbody.date_end" type="date"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="3">
          <b-form-group label="Firma 1">

          </b-form-group>
        </b-col>
        <b-col lg="3">
          <b-form-group label="Firma 2">

          </b-form-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-show="data.certificationbody.id > 0" class="mr-2" @click="loadData(data.certificationbody.users_id, data.certificationbody.id)">Aggiorna</b-btn>
          <b-btn variant="success" @click="saveData()">Salva</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "curriculumcertificationbody",
  data() {
    return {
      show: false,
      data: null
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    loadData( usersId, id ){
      this.openHttp("qualifications/curriculum/certificationbody/", { users_id: usersId, id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("qualifications/curriculum/certificationbody/save", this.data.certificationbody).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('update');
    }
  }
}
</script>