<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" :title="'Parametro - ' + data.contractsparameterstype.id" size="lg" @hidden="closeForm()" @close="closeForm()">
            <b-row>
                <b-col lg="6">
                    <b-form-group label="Tipo parametro">
                        <b-form-input v-model="data.contractsparameterstype.parametertype"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Stato">
                        <b-form-select v-model="data.contractsparameterstype.active" :options="optionsStatus" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Formato">
                        <b-form-select v-model="data.contractsparameterstype.format" :options="optionsFormat" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Ordine">
                        <b-form-input type="number" v-model="data.contractsparameterstype.parameterorder"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="SQL attivazione">
                <b-form-textarea v-model="data.contractsparameterstype.sqlenable" rows="5" max-rows="10"/>
            </b-form-group>
            <b-form-group label="Default">
                <b-form-input v-model="data.contractsparameterstype.parameterdefault"/>
            </b-form-group>
            <b-form-group label="Risposte" v-show="data.contractsparameterstype.format === 'select'">
                <b-btn variant="primary" size="sm" @click="newAnswer">Nuovo</b-btn>
                <b-table :items="data.contractsparameterstype.answers" class="mt-3" :fields="fields">
                    <template v-slot:cell(paramval)="row">
                        <b-form-input v-model="row.item.paramval" size="sm"/>
                    </template>
                    <template v-slot:cell(paramlabel)="row">
                        <b-form-input v-model="row.item.paramlabel" size="sm"/>
                    </template>
                    <template v-slot:cell(id)="row">
                        <b-btn variant="danger" size="sm" @click="data.contractsparameterstype.answers.splice(row.index, 1)">Elimina</b-btn>
                    </template>
                </b-table>
            </b-form-group>
            <b-form-group label="Enti">
                <b-form-checkbox-group v-model="data.contractsparameterstype.certificationbodies">
                    <b-form-checkbox v-for="(item, index) of data.certificationbodies" :key="index" :value="item.id">{{ item.val }}</b-form-checkbox>
                </b-form-checkbox-group>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.contractsparameterstype.id> 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "contractsparameterstype",
    data() {
        return {
            show: false,
            data: null,
            optionsFormat: [{
                id: "string",
                val: "Stringa"
            },{
                id: "bool",
                val: "Si/No"
            },{
                id: "select",
                val: "Selezione"
            },{
                id: "date",
                val: "Data"
            },{
                id: "number",
                val: "Numero"
            },{
                id: "text",
                val: "Casella di testo"
            }],
            optionsStatus: [{
                id: true,
                val: "Attivo"
            },{
                id: false,
                val: "Non Attivo"
            }],
            fields: [{
                key: 'paramval',
                label: "Valore",
            },{
                key: 'paramlabel',
                label: 'Label',
            },{
                key: 'id',
                label: "",
            }],
            fields2: [{
                key: 'standards_id',
                label: 'Norma',
                formatter: value => {
                    return this.data.standards.find(x => x.id === value).val;
                }
            },{
                key: 'nacesiafcodes_id',
                label: 'IAF',
                formatter: value => {
                    return this.data.nacesiafcodes.find(x => x.id === value).val;
                }
            },{
                key: 'contractsparameterstypes_id',
                label: 'Domanda',
                formatter: value => {
                    return this.data.contractsparameterstypes.find(x => x.id === value).val;
                }
            },{
                key: 'contractsparameterstypesanswers_id',
                label: 'Risposta',
            }],
        }
    },
    methods: {
        loadData(id){
            this.openHttp("settings/audits/contractsparameterstype/", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        newAnswer(){
            this.data.contractsparameterstype.answers.push({
                id: 0,
                paramval: null,
                paramlabel: null
            });
        },
        saveData(){
            this.saveHttp("settings/audits/contractsparameterstype/save", this.data.contractsparameterstype).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("settings/audits/contractsparameterstype/delete", { id: this.data.contractsparameterstype.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>