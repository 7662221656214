<template>
  <div>
    <curriculum ref="curriculum" @update="loadData"/>
    <b-modal v-model="showFilter" title="Filtra">
      <b-form-group label="Persona">
        <b-form-input v-model="tempFilter.fullname"/>
      </b-form-group>
      <b-form-group label="Stato">
        <b-form-select v-model="tempFilter.active" :options="addNullCombo(activeCombo)" value-field="id" text-field="val"/>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="filterSet">Filtra</b-btn>
          <b-btn variant="warning" @click="filterReset">Reset</b-btn>
        </div>
      </template>
    </b-modal>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="secondary" @click="filterOpen" v-if="level >= 2">Filtra</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 205) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="items.data" :fields="fields" :busy="isBusy" :no-local-sorting="true" :sort-by.sync="search.sortby" :sort-desc.sync="search.sortdesc" @sort-changed="sortingChange" @row-clicked="$refs.curriculum.loadData($event.id)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
    <tablebottom :busy="isBusy" :currentpage="search.page" :total="items.total" @updateCurrentPage="search.page = $event" @loadData="loadData()"/>
  </div>
</template>

<script>
import curriculum from './curriculum.vue'

export default {
  name: "curricula",
  components : {
    curriculum
  },
  data() {
    return {
      isBusy: true,
      showFilter: false,
      search: {
        page: 1,
        filters: {
          certificationbodies_id: null,
          active: true,
          fullname: null,
        },
        sortby: 'fullname',
        sortdesc: false,
      },
      tempFilter: {
        certificationbodies_id: null,
        active: true,
        user: null,
      },
      activeCombo: [{
        id: true,
        val: "Attivi"
      },{
        id: false,
        val: "Non Attivi"
      }],
      fields: [{
        key: 'fullname',
        label: 'Nome completo',
        sortable: true,
      },{
        key: 'certificatescount',
        label: "Attestati",
      },{
        key: 'experiencescount',
        label: "Esperienze",
      },{
        key: 'educationcount',
        label: "Educazione",
      },{
        key: 'consultanciescount',
        label: "Consulenze",
      },{
        key: 'auditlogscount',
        label: "Audit logs",
      }],
      items: {
        total: 0,
        data: [],
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "curricula").accesslevel;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("qualifications/curricula/", this.search).then((result) => {
        this.items = result;
        this.isBusy = false;
      })
    },
    filterOpen(){
      this.showFilter = true;
    },
    filterSet(){
      this.showFilter = false;
      this.search.page = 1;
      this.search.filters = this.tempFilter;
      this.loadData();
    },
    filterReset(){
      this.tempFilter = {
        certificationbodies_id: null,
        active: true,
        fullname: null
      }
      this.filterSet();
    },
    sortingChange(ctx) {
      this.search.sortby = ctx.sortBy;
      this.search.sortdesc = ctx.sortDesc;
      this.loadData();
    }
  }
}
</script>