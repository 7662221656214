<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" :title="'Non conformità - ' + data.nonconformity.id" size="lg">
            <auditsignature ref="signature" @update="loadData(data.nonconformity.audits_id, data.nonconformity.id)"/>
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Apertura" active>
                        <b-form-group label="Non conformità">
                            <b-row>
                                <b-col v-for="(item, index) in data.nonconformity.languages" :key="index">
                                    <b-input-group :prepend="item.lang">
                                        <b-form-textarea v-model="item.nonconformity" rows="4"/>
                                    </b-input-group>
                                    <b-btn variant="secondary" size="sm" class="mt-2" v-show="item.nonconformity !== null && item.nonconformity === item.nonconformity.toUpperCase()" @click="item.nonconformity = item.nonconformity.toLowerCase()">Minuscolo</b-btn>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <b-row>
                            <b-col lg="8">
                                <b-form-group label="Punti norma">
                                    <b-form-checkbox-group v-model="data.nonconformity.standardspoints">
                                        <div v-for="(item, index) of data.points" :key="index">
                                            <strong>{{ item.standard }}: </strong>
                                            <b-form-checkbox v-for="(item2, index2) of item.points" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
                                        </div>
                                    </b-form-checkbox-group>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4">
                                <b-form-group label="Fase">
                                    <b-form-select v-model="data.nonconformity.auditsphases_id" :options="data.auditsphases" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="4">
                                <b-form-group label="Aperta da">
                                    <b-form-select v-model="data.nonconformity.open_users_id" :options="data.users" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="2">
                                <b-form-group label="Tipologia">
                                    <b-form-select v-model="data.nonconformity.auditsnonconformitiescertificationbodiestypes_id" :options="data.types" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row>
                            <b-col lg="4">
                                <b-form-group label="Accettata / rifiutata da">
                                    <b-form-select v-model="data.nonconformity.approve_workers_id" :options="data.workers" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="2">
                                <b-form-group label="Esito accettazione / rifiuto">
                                    <b-form-select v-model="data.nonconformity.acceptdecision" :options="acceptdecisionCombo" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-form-group label="Note accettazione / rifiuto">
                            <b-row>
                                <b-col v-for="(item, index) in data.nonconformity.languages" :key="index">
                                    <b-input-group :prepend="item.lang">
                                        <b-form-textarea v-model="item.acceptnote" rows="4"/>
                                    </b-input-group>
                                    <b-btn variant="secondary" size="sm" class="mt-2" v-show="item.acceptnote !== null && item.acceptnote === item.acceptnote.toUpperCase()" @click="item.acceptnote = item.acceptnote.toLowerCase()">Minuscolo</b-btn>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </b-tab>
                    <b-tab title="Gestione">
                        <b-form-group label="Analisi cause">
                            <b-row>
                                <b-col v-for="(item, index) in data.nonconformity.languages" :key="index">
                                    <b-input-group :prepend="item.lang">
                                        <b-form-textarea v-model="item.cause" rows="4"/>
                                    </b-input-group>
                                    <b-btn variant="secondary" size="sm" class="mt-2" v-show="item.cause !== null && item.cause === item.cause.toUpperCase()" @click="item.cause = item.cause.toLowerCase()">Minuscolo</b-btn>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <b-form-group label="Trattamento">
                            <b-row>
                                <b-col v-for="(item, index) in data.nonconformity.languages" :key="index">
                                    <b-input-group :prepend="item.lang">
                                        <b-form-textarea v-model="item.treatment" rows="4"/>
                                    </b-input-group>
                                    <b-btn variant="secondary" size="sm" class="mt-2" v-show="item.treatment !== null && item.treatment === item.treatment.toUpperCase()" @click="item.treatment = item.treatment.toLowerCase()">Minuscolo</b-btn>
                                </b-col>
                            </b-row>
                        </b-form-group>
                        <b-form-group label="Azione correttiva">
                            <b-row>
                                <b-col v-for="(item, index) in data.nonconformity.languages" :key="index">
                                    <b-input-group :prepend="item.lang">
                                        <b-form-textarea v-model="item.correctiveaction" rows="4"/>
                                    </b-input-group>
                                    <b-btn variant="secondary" size="sm" class="mt-2" v-show="item.correctiveaction !== null && item.correctiveaction === item.correctiveaction.toUpperCase()" @click="item.correctiveaction = item.correctiveaction.toLowerCase()">Minuscolo</b-btn>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </b-tab>
                    <b-tab title="Efficacia">
                        <b-row>
                            <b-col lg="4">
                                <b-form-group label="Accettata / rifiutata da">
                                    <b-form-select v-model="data.nonconformity.effectiveness_users_id" :options="data.users" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="2">
                                <b-form-group label="Esito accettazione / rifiuto">
                                    <b-form-select v-model="data.nonconformity.effectivenessdecision" :options="effectivenessdecisionCombo" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-form-group label="Note efficacia">
                            <b-row>
                                <b-col v-for="(item, index) in data.nonconformity.languages" :key="index">
                                    <b-input-group :prepend="item.lang">
                                        <b-form-textarea v-model="item.effectivenessnote" rows="4"/>
                                    </b-input-group>
                                    <b-btn variant="secondary" size="sm" class="mt-2" v-show="item.effectivenessnote !== null && item.effectivenessnote === item.effectivenessnote.toUpperCase()" @click="item.effectivenessnote = item.effectivenessnote.toLowerCase()">Minuscolo</b-btn>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </b-tab>
                </b-tabs>
            </b-card>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.nonconformity.id > 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditnonconformity",
    data() {
        return {
            show: false,
            data: null,
            acceptdecisionCombo: [{
                id: null,
                val: 'In Corso ...'
            },{
                id: true,
                val: 'Accettata'
            },{
                id: false,
                val: 'Non accettata'
            }],
            effectivenessdecisionCombo: [{
                id: null,
                val: 'In Corso ...'
            },{
                id: true,
                val: 'Accettata'
            },{
                id: false,
                val: 'Non accettata'
            }]
        }
    },
    methods: {
        loadData( auditsId, id ){
            this.openHttp("audits/nonconformity/", { audits_id: auditsId, id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("audits/nonconformity/save", this.data.nonconformity).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("audits/nonconformity/delete", this.data.nonconformity).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        saveVeto(auditsId, id, decision, note){
            this.saveHttp("audits/nonconformity/vetosave", { audits_id: auditsId, id: id, decision: decision, note: note }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        unlockVeto(auditsId, id){
            this.saveHttp("audits/nonconformity/vetounlock", { audits_id: auditsId, id: id }).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>