<template>
    <div>
        <b-modal v-model="show" v-if="data !== null" :title="'Nota - ' + data.note.id" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="Data">
                <b-form-input type="date" v-model="data.note.date"/>
            </b-form-group>
            <b-form-group label="Utente">
                <b-form-select v-model="data.note.users_id" :options="data.users" value-field="id" text-field="val"/>
            </b-form-group>
            <b-form-group label="Note">
                <b-form-textarea v-model="data.note.note" rows="4"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.note.id > 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "auditnote",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    methods: {
        loadData( id, audits_id ){
            this.openHttp("audits/note/", { id: id, audits_id: audits_id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("audits/note/save", this.data.note).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("audits/note/delete", this.data.note).then((result) => {
                if (result !== null) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        }
    }
}
</script>