<template>
    <div>
        <b-modal v-if="data != null" v-model="show" size="xl" :title="'Standard - ' + data.standard.id" scrollable @hidden="closeForm()" @close="closeForm()">
            <b-card no-body>  
                <b-tabs card>
                    <b-tab title="Standard" active>
                        <b-row>
                            <b-col lg="4">
                                <b-form-group label="Norma">
                                    <b-form-input v-model="data.standard.standard"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="2">
                                <b-form-group label="Stato">
                                    <b-form-select v-model="data.standard.active" :options="optionActive" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="2">
                                <b-form-group label="Area">
                                    <b-form-select v-model="data.standard.standardsareas_id" :options="data.standardsareas" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4">
                                <b-form-group label="Norma superata">
                                    <b-form-select v-model="data.standard.standards_id" :options="addNullCombo(data.standards, 'Nessuna')" value-field="id" text-field="val"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-form-group label="Livelli di rischio">
                            <b-form-checkbox-group v-model="data.standard.riskslevels">
                                <b-form-checkbox v-for="(item, index) in data.riskslevels" :key="index" :value="item.id">{{ item.val }}</b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>
                    </b-tab>
                    <b-tab title="Esclusioni">
                        <b-btn variant="primary" class="mb-2" size="sm" @click="exclusionAdd">Nuovo</b-btn>
                        <b-table :sticky-header="(winHeight - 390) + 'px'" :items="data.standard.exclusions" :fields="fieldsExclusions">
                            <template v-slot:cell(exclusionpoint)="row">
                                <b-form-input v-model="row.item.exclusionpoint"/>
                            </template>
                            <template v-slot:cell(standardspoints_id)="row">
                                <b-form-select v-model="row.item.standardspoints_id" :options="standardspointsCombo" value-field="id" text-field="val"/>
                            </template>
                            <template v-slot:cell(id)="row">
                                <b-btn variant="danger" size="sm" @click="data.standard.exclusions.splice(row.index,1);">Elimina</b-btn>
                            </template>
                        </b-table>
                    </b-tab>
                    <b-tab title="Descrizione">
                        <b-table :sticky-header="(winHeight - 390) + 'px'" :items="data.standard.descriptions" :fields="fieldsDescriptions">
                            <template v-slot:cell(standarddescription)="row">
                                <b-form-input v-model="row.item.standarddescription"/>
                            </template>
                        </b-table>
                    </b-tab>
                    <b-tab title="NACE e rischio">
                        <b-table :sticky-header="(winHeight - 390) + 'px'" :items="data.naces" :fields="fieldsRisksNaces">
                            <template v-slot:cell(yesno)="row">
                                <b-form-checkbox :checked="data.standard.nacesriskslevels.findIndex(x => x.id === row.item.id) > -1" @change="yesNoRisk(row.item.id, $event)"/>
                            </template>
                            <template v-slot:cell(riskslevels_id)="row">
                                <b-form-radio-group v-if="data.standard.nacesriskslevels.findIndex(y => y.id === row.item.id) > -1" v-model="data.standard.nacesriskslevels.find(y => y.id === row.item.id).riskslevels_id" :options="data.riskslevels.filter(x => data.standard.nacesriskslevels.findIndex(y => y.id === row.item.id) > -1).filter(x => data.standard.riskslevels.includes(x.id))" value-field="id" text-field="val"></b-form-radio-group>
                            </template>
                        </b-table>
                    </b-tab>
                    <b-tab title="Punti">
                        <b-btn variant="primary" class="mb-2" size="sm" @click="pointAdd()">Nuovo</b-btn>
                        <b-table :sticky-header="(winHeight - 390) + 'px'" :items="data.standard.standardspoints" :fields="fieldsPoints">
                            <template v-slot:cell(point)="row">
                                <b-form-input v-model="row.item.point"/>
                            </template>
                            <template v-slot:cell(pointdescription)="row">
                                <b-form-textarea v-model="row.item.pointdescription" rows="3" max-rows="20"/>
                            </template>
                            <template v-slot:cell(orderstandardspoint)="row">
                                <b-form-input type="number" v-model="row.item.orderstandardspoint"/>
                            </template>
                            <template v-slot:cell(needyard)="row">
                                <b-form-select v-model="row.item.needyard" :options="needYardCombo" value-field="id" text-field="val"/>
                            </template>
                            <template v-slot:cell(multisite)="row">
                                <b-form-select v-model="row.item.multisite" :options="multisiteCombo" value-field="id" text-field="val"/>
                            </template>
                            <template v-slot:cell(areas)="row">
                                <multiselect v-model="row.item.areasSupport" :options="data.areas" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="row.item.areas = updateCombo(row.item.areasSupport);"/>
                            </template>
                            <template v-slot:cell(id)="row">
                                <b-btn variant="danger" size="sm" @click="data.standard.standardspoints.splice(row.index,1);">Elimina</b-btn>
                            </template>
                        </b-table>
                    </b-tab>
                    <b-tab title="Giorni uomo">
                        <b-table :sticky-header="(winHeight - 350) + 'px'" :items="data.standard.mandays" :fields="fieldsMandays">
                            <template v-slot:cell(mandays)="row">
                                <b-form-input v-model="row.item.mandays" size="sm"/>
                            </template>                        
                        </b-table>
                    </b-tab>
                    <b-tab title="Report e domande">
                        <b-table :sticky-header="(winHeight - 350) + 'px'" :items="data.standard.standardspoints" :fields="fieldsReportLanguages">
                            <template v-slot:cell(report)="row">

                                <b-card no-body>
                                <b-tabs card>
                                    <b-tab v-for="(item, index) of data.standard.reportlanguages.filter(x => x.id === row.item.id)" :key="index" :title="item.lang">
                                        <b-form-input v-model="item.pointname"/>
                                        <b-form-textarea v-model="item.reportjinja" rows="3" max-rows="40"/>
                                    </b-tab>
                                </b-tabs>
                                </b-card>
                            </template>
                            <template v-slot:cell(questions)="row">
                                <div v-for="(item, index) of data.questions.filter(x => x.standardspoints_id === row.item.id)" :key="index">
                                    <div>{{ item.id + ') ' + item.question }}<font color="red" v-show="item.notapplicable === true"> Non applicabile</font><font color="red" v-show="item.multisite === true"> Multisito</font> <font color="red" v-show="item.multinace === true"> Multinace</font></div>
                                    <ul>
                                        <li v-for="(item2,index2) of item.subquestions" :key="index2">{{ item2.id }}) {{ item2.question }}</li>
                                    </ul>
                                </div>
                            </template>
                        </b-table>
                    </b-tab>
                </b-tabs>
            </b-card>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.standard.id> 0" @click="deleteData()">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "standard",
    data() {
        return {
            show: false,
            data: null,
            optionActive: [{
                id: true,
                val: "Attivo",
            },{
                id: false,
                val: "Non attivo",
            }],
            needYardCombo: [{
                id: true,
                val: 'Cantiere',
            },{
                id: false,
                val: 'Solo sede',
            }],
            multisiteCombo: [{
                id: true,
                val: 'Tutti i siti',
            },{
                id: false,
                val: 'Una volta',
            }],
            fieldsDescriptions: [{
                key: "lang",
                label: "Lingua",
            },{
                key: "standarddescription",
                label: "Descrizione",
            }],
            fieldsExclusions: [{
                key: "exclusionpoint",
                label: "Esclusione",
            },{
                key: "standardspoints_id",
                label: "Punto norma",
            },{
                key: "id",
                label: "",
            }],
            fieldsRisksNaces: [{
                key: "nacecomplete",
                label: "NACE"
            },{
                key: "iafcode",
                label: "IAF"
            },{
                key: "description",
                label: "Descrizione"
            },{
                key: "yesno",
                label: "Si/No"
            },{
                key: "riskslevels_id",
                label: "Rischio"
            }],
            fieldsPoints: [{
                key: "point",
                label: "Punto",
                thStyle: {
                    width: '5%'
                },
            },{
                key: 'orderstandardspoint',
                label: 'Ordine',
                thStyle: {
                    width: '5%'
                },
            },{
                key: 'needyard',
                label: 'Cantiere',
                thStyle: {
                    width: '10%'
                },
            },{
                key: 'multisite',
                label: 'Multisito',
                thStyle: {
                    width: '10%'
                },
            },{
                key: 'areas',
                label: 'Area',
                thStyle: {
                    width: '65%'
                },
            },{
                key: "id",
                label: "",
                thStyle: {
                    width: '5%'
                },
            }],
            fieldsMandays: [{
                key: "min_employees",
                label: "Min",
            },{
                key: "max_employees",
                label: "Max",
            },{
                key: "riskslevels_id",
                label: "Rischio",
                formatter: (value, key, item) => {
                    return this.data.riskslevels.find( x => x.id === item.riskslevels_id).val;
                }
            },{
                key: "mandays",
                label: "Giorni uomo",
            }],
            fieldsReportLanguages: [{
                key: "point",
                label: "Punto",
                thStyle: {
                    width: '5%'
                },
            },{
                key: "report",
                label: 'Report',
                thStyle: {
                    width: '55%'
                },
            },{
                key: "questions",
                label: 'Domande',
                thStyle: {
                    width: '40%'
                },
            }]
        }
    },
    computed: {
        winHeight (){
            return this.$store.getters.getWinHeight;
        },
        standardspointsCombo(){
            return [{id: null, val: "-"}].concat(this.data.standard.standardspoints.filter(x => x.id > 0).map(x => ({id: x.id, val: x.point})));
        }
    },
    methods: {
        loadData(id){
            this.openHttp("settings/audits/standard/", { id: id }).then((result) => {
                console.log(result)

                this.data = result;
                this.reloadWorkersRolesTypes();
                this.show = true;
            })
        },
        reloadWorkersRolesTypes(){
            for ( let item of this.data.standard.standardspoints ) item.areasSupport = this.convertCombo( this.data.areas, item.areas );
        },
        pointAdd(){
            this.data.standard.standardspoints.push({
                id: 0, 
                point: null,
                orderstandardspoint: 99,
                notapplicable: false,
                needyard: false,
                multisite: false,
                workersrolestypes: [],
                areas: [],
            });
            this.reloadWorkersRolesTypes();
        },
        exclusionAdd(){
            this.data.standard.exclusions.push({
                id: 0,
                exclusionpoint: null
            });
        },
        yesNoRisk(id, event){
            if (event === true){
                this.data.standard.nacesriskslevels.push({
                    id: id,
                    riskslevels_id: null,
                });
            } else {
                this.data.standard.nacesriskslevels.splice(this.data.standard.nacesriskslevels.findIndex(x => x.id === id), 1);
            }
        },
        saveData(){
            this.saveHttp("settings/audits/standard/save", this.data.standard).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("settings/audits/standard/delete", { id: this.data.standard.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>