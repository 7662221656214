<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Contratto - ' + data.contract.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <contractstandard ref="contractstandard" @update="loadData(data.contract.id)"/>
      <contractnote ref="note" @update="loadData(data.contract.id)"/>
      <contractsite ref="site" @update="loadData(data.contract.id)"/>
      <contractscope ref="scope" @update="loadData(data.contract.id)"/>
      <contractrelease ref="release" @update="loadData(data.contract.id)"/>
      <contractcost ref="cost" @update="loadData(data.contract.id)"/>
      <contractparameter ref="parameter" @update="loadData(data.contract.id)"/>
      <contractreview ref="review" @update="loadData(data.contract.id)"/>
      <contracthistory ref="history" @update="loadData(data.contract.id)"/>
      <contractdocument ref="document" @update="loadData(data.contract.id)"/>
      <audit ref="audit" @update="loadData(data.contract.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Contratto" active>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <company ref="company" @update="loadData(data.contract.id)"/>
                  <label><b-link v-if="data.contract.id > 0" @click="$refs.company.loadData(data.contract.companies_id)">Azienda</b-link><span v-else>Azienda</span></label>
                  <multiselect v-model="companies_idSupport" :options="data.companies" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.contract.companies_id = updateComboSimple(companies_idSupport)" @search-change="asyncCompanies"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Contratto">
                  <b-form-input v-model="data.contract.contract"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Emissione">
                  <b-form-input type="date" v-model="data.contract.issuedate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Scadenza">
                  <b-form-input type="date" v-model="data.contract.expiracydate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Stato">
                  <b-form-select v-model="data.contract.contractsstatus_id" :options="data.contractsstatus" value-field="id" text-field="val" :disabled="data.contract.id === 0"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <b-form-group label="Ente">
                  <b-form-select v-model="data.contract.certificationbodies_id" :options="data.certificationbodies" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Tipologia">
                  <b-form-select v-model="data.contract.contractstypes_id" :options="data.contractstypes" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2" v-show="data.contract.id > 0">
                <b-form-group label="Referente">
                  <b-form-select v-model="data.contract.workers_id" :options="data.workers" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Lingue">
                  <multiselect v-model="languagesSupport" :options="data.languages" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.contract.languages = updateCombo(languagesSupport)"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Norme" v-if="data.contract.id > 0">
              <b-btn variant="primary" class="mr-2" size="sm" @click="$refs.contractstandard.loadData(data.contract.id, 0)">Nuovo</b-btn>
              <b-table no-border-collapse :sticky-header="(winHeight - 205) / 3 + 'px'" class="mt-2" :items="data.standards" :fields="fields.standards" @row-clicked="$refs.contractstandard.loadData(data.contract.id, $event.id)"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-btn variant="primary" class="mb-2" size="sm" @click="$refs.note.loadData(0, data.contract.id)">Nuova</b-btn>
              <b-table responsive :fields="fields.notes" :items="data.notes" class="mt-2" :sticky-header="(winHeight - 305) + 'px'" @row-clicked="$refs.note.loadData($event.id, data.contract.id)"/>
            </b-form-group>
          </b-tab>
          <b-tab title="Siti" v-if="data.contract.id > 0">
            <b-btn variant="primary" size="sm" @click="$refs.site.loadData(data.contract.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 385)+ 'px'" class="mt-2" :items="data.sites" :fields="fields.sites" @row-clicked="$refs.site.loadData(data.contract.id, $event.id)">
              <template v-slot:cell(company)="row">
                <nobr>{{ [row.item.active === false ? 'NON ATTIVA' : null, row.item.ismain === true ? 'Principale' : null, row.item.isregistered === true ? 'Legale' : null, row.item.isoperative === true ? 'Operativo' : null].filter(x => x!= null).join(", ") }}</nobr>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Scopi" v-if="data.contract.id > 0">
            <b-btn variant="primary" size="sm" @click="$refs.scope.loadData(data.contract.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 385) + 'px'" class="mt-2" :items="data.scopes" :fields="fields.scopes" @row-clicked="$refs.scope.loadData(data.contract.id, $event.id)">
              <template v-slot:cell(languages)="row">
                <div v-for="(item,index) of row.value" :key="index">
                <b>{{ item.language }}: </b>{{ item.scopeval }}
                </div>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Parametri" v-if="data.contract.id > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 385) + 'px'" :items="data.parameters" :fields="fields.parameters" @row-clicked="$refs.parameter.loadData(data.contract.id, $event.id)"/>
          </b-tab>
          <b-tab title="Certificati" v-if="data.contract.id > 0">
            <b-btn variant="primary" size="sm" class="mr-2" @click="$refs.release.loadData(data.contract.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 385) + 'px'" class="mt-2" :items="data.releases" :fields="fields.releases" @row-clicked="$refs.release.loadData(data.contract.id, $event.id)">
              <template v-slot:cell(scopes)="row">
                <div v-for="(item,index) of row.value" :key="index">
                <b>{{ item.lang }}: </b>{{ item.scope }}
                </div>
              </template>
              <template v-slot:cell(sites)="row">
                <div v-for="(item,index) of row.value" :key="index">
                  <nobr>
                    <b>{{ [item.ismain === true ? 'Pr.' : null, item.isregistered === true ? 'Leg.' : null, item.isoperative === true ? 'Op.' : null].filter(x => x!= null).join(", ") }}: </b>
                    {{ item.site }}<br>
                    <b>Risk {{ item.riskslevel }}: {{ item.phases }}</b>
                  </nobr>
                </div>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Application" v-if="data.contract.id > 0 && data.contract.certificationbodies_id === 133">
            <b-row>
              <b-col lg="2">
                <b-form-group label="Data application">
                  <b-form-input type="date" v-model="data.contract.create_date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-btn variant="primary" size="sm" @click="$refs.review.loadData(data.contract.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 335) + 'px'" class="mt-2" :items="data.reviews" :fields="fields.reviews" @row-clicked="$refs.review.loadData(data.contract.id, $event.id)"/>
          </b-tab>
          <b-tab title="Anni/Prezzi/Audits" v-if="data.contract.id > 0">
            <b-btn variant="primary" size="sm" class="mr-2" @click="$refs.cost.loadData(data.contract.id, 0)">Nuovo</b-btn>
            <b-btn variant="info" size="sm" class="mr-2" @click="prepareSteps()">Genera Fasi</b-btn>
            <b-btn variant="warning" size="sm" class="mr-2" @click="calculateMandays()">Calcola gg/uomo</b-btn>
            <b-btn v-if="data.contract.contractsstatus_id >= 2" variant="info" size="sm" class="mr-2" @click="prepareAudits()">Genera Audits</b-btn>
            <b-btn variant="danger" size="sm" @click="deadlineFix()">Scadenze</b-btn>
            <b-table no-border-collapse class="mt-3" :items="data.costsyears" :fields="fields.costsyears" @row-clicked="$refs.cost.loadData(data.contract.id, $event.id)">
              <template v-slot:cell(audits_id)="row">
                <b-link v-show="row.item.audits_id !== null" variant="info" size="sm" @click="$refs.audit.loadData(row.value)">{{ row.value }}</b-link>
              </template>
              <template v-slot:cell(sites)="row">
                <div v-for="(item, index) of row.item.sites" :key="index">{{ item.site }}</div>
              </template>
            </b-table>
            <b-card v-if="data.quotessteps != null">
              <p class="card-text"><strong>Preventivo {{ data.quotessteps.id }}</strong>: {{ data.quotessteps.company }} - {{ data.quotessteps.certificationbody }}</p>
              <b-table no-border-collapse :items="data.quotessteps.steps" :fields="fields.quotessteps">
                <template v-slot:cell(service)="row">{{ row.value }}</template>
              </b-table>
            </b-card>
          </b-tab>
          <b-tab title="Decisioni" v-if="data.contract.contractsstatus_id > 1">
            <b-btn variant="primary" size="sm" @click="$refs.history.loadData(data.contract.id, 0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 385) + 'px'" :items="data.histories" :fields="fields.histories" class="mt-2"  @row-clicked="$refs.history.loadData(data.contract.id, $event.id)"/>
          </b-tab>
          <b-tab title="Chiusura" :disabled="data.contract.contractsstatus_id < 3">
            <b-row>
              <b-col lg="4">
                <b-form-group label="Chiusura">
                  <b-form-select v-model="data.contract.contractsclosuretypes_id" :options="data.contractsclosuretypes" value-field="id" text-field="val"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Chiusura">
                  <b-form-input type="date" v-model="data.contract.closuredate"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Email" v-if="data.contract.id > 0">
            <mail ref="mail" @update="loadData(data.contract.id)"/>
            <mailnew ref="mailnew" @update="loadData(data.contract.id)"/>
            <b-row class="mb-2" align-h="end">
              <b-col lg="3">
                <b-form-input v-model="fields.mailsCriteria" size="sm" placeholder="Filtra..."/>
              </b-col>
            </b-row>
            <b-table :sticky-header="(winHeight - 205) + 'px'" :tbody-tr-class="rowClassMails" responsive :items="data.mails" :fields="fields.mails" @row-clicked="$refs.mail.loadData($event.id)" :filter="fields.mailsCriteria"/>
          </b-tab>
          <b-tab title="Documenti">
            <b-btn variant="info" class="mr-2" size="sm" @click="$refs.document.generateData(data.contract.id)">Genera</b-btn>
            <b-btn variant="primary" size="sm" @click="$refs.document.downloadDataAll(data.contract.id)">Scarica tutto</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 385)+ 'px'" :tbody-tr-class="rowClassDocuments" class="mt-2" :items="data.documents" :fields="fields.documents" @row-clicked="$refs.document.loadData(data.contract.id, $event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="warning" class="mr-2" v-show="row.item.datetime !== null && row.item.locked === false" size="sm" @click="$refs.document.lockData(row.item.id, true)">Blocca</b-btn>
                <b-btn v-if="row.item.datetime !== null" variant="primary" size="sm" @click="$refs.document.downloadData(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab :title="'Errori - ' + errors.length" v-show="errors.length > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 305)+ 'px'" :items="errors" :fields="fields.errors"/>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-modal v-if="showMail" v-model="showMail" title="Manda" size="lg">
            <b-table :items="data.contacts" :fields="fields.contacts" :sticky-header="(winHeight - 400) + 'px'" sortable="true">
              <template v-slot:cell(selected)="row">
                <b-form-checkbox v-model="row.item.selected"/>
              </template>
            </b-table>
            <template v-slot:modal-footer>
              <div class="w-100">
                <b-btn variant="primary" @click="prepareMail()">Prepara Mail</b-btn>
              </div>
            </template>
          </b-modal>
          <b-btn variant="primary" v-show="data.contract.id > 0" class="mr-2" @click="loadData(data.contract.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData()">Salva</b-btn>
          <b-btn variant="warning" class="mr-2" v-show="data.contract.id > 0" @click="duplicateData()">Duplica</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.contract.id > 0" @click="deleteData()">Elimina</b-btn>
          <b-btn variant="primary" v-show="data.contract.id > 0" @click="showMail = true">Prepara Mail</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import contractstandard from './contractscomponents/contractstandard.vue';
import contractnote from './contractscomponents/contractnote.vue';
import contractsite from './contractscomponents/contractsite.vue';
import contractscope from './contractscomponents/contractscope.vue';
import contractparameter from './contractscomponents/contractparameter.vue';
import contractrelease from './contractscomponents/contractrelease.vue';
import contractcost from './contractscomponents/contractcost.vue';
import contracthistory from './contractscomponents/contracthistory.vue';
import contractreview from './contractscomponents/contractreview.vue';
import contractdocument from './contractscomponents/contractdocument.vue';

export default {
  name: "contract",
  components: { contractstandard, contractnote, contractsite, contractscope, contractparameter, contractrelease, contractcost, contracthistory, contractreview, contractdocument },
  data() {
    return {
      show: true,
      data: null,
      errors: [],
      companies_idSupport: [],
      languagesSupport: [],
      showMail: false,
      comboActive: [{
        id: true,
        val: "Attivo"
      },{
        id: false,
        val: "Non Attivo"
      },],
      fields: {
        standards: [{
          key: 'standard',
          label: "Norma",
        },{
          key: 'certificatecode',
          label: 'Certificato',
        },{
          key: 'certificatefirstissue',
          label: 'Prima emissione',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'datetransferissue',
          label: 'Emissione transfer / ricert',
          formatter: value => {
            if (this.data.contract.contractstypes_id === 1) return "No";
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'datetransferexpirancy',
          label: "Scadenza transfer / ricert",
          formatter: value => {
            if (this.data.contract.contractstypes_id === 1) return "No";
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        }],
        notes: [{
          key: 'date',
          label: "Data",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [d.getDate(), d.getMonth() + 1, d.getFullYear()].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: 'fullname',
          label: "Persona",
        },{
          key: 'note',
          label: "Nota",
        }],
        sites: [{
          key: 'site',
          label: 'Sito',
        },{
          key: 'company',
          label: 'Anagrafica',
        },{
          key: 'employeesnumber',
          label: 'Lav. totali',
        },{
          key: 'employeeseffectivenumber',
          label: 'Lav effettivi',
        },{
          key: 'employeesexternal',
          label: 'Lav esterni',
        },{
          key: 'employeesrepetitive',
          label: 'Mans. ripetitive',
        },{
          key: 'shifts',
          label: 'Turni',
        },{
          key: "naces",
          label: "NACE",
        }],
        scopes: [{
          key: "languages",
          label: "Scopo",
        },{
          key: "naces",
          label: "NACE",
        },{
          key: "standardsexclusions",
          label: "Esclusioni"
        }],
        parameters: [{
          key: 'type',
          label: 'Tipo',
        },{
          key: 'val',
          label: 'Valore',
        }],
        releases: [{
          key: "standard",
          label: "Norma",
          sortable: true,
          thStyle: { width: "10%" },
        },{
          key: "revnumber",
          label: "Rev",
          sortable: true,
          thStyle: { width: "5%" },
        },{
          key: "scopes",
          label: "Scopi",
          thStyle: { width: "30%" },
        },{
          key: "sites",
          label: "Siti",
          thStyle: { width: "25%" },
        },{
          key: "issuedate",
          label: "Emissione",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          thStyle: { width: "5%" }
        },{
          key: "validfromdate",
          label: "Validità",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          thStyle: { width: "5%" }
        },{
          key: "expiracydate",
          label: "Scadenza",
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          thStyle: { width: "5%" }
        },{
          key: "note",
          label: "Note",
          thStyle: { width: "15%" }
        }],
        reviews: [{
          key: "date",
          label: 'Data',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          }
        },{
          key: "user",
          label: "Approvato da",
        },{
          key: 'clientdata',
          label: 'Dati clienti',
          formatter: value => {
            return value === true ? "Ok" : "Ko";
          },
        },{
          key: 'scopecover',
          label: 'Campo di applicazione',
          formatter: value => {
            return value === true ? "Ok" : "Ko";
          },
        },{
          key: 'sitecover',
          label: 'Copertura siti',
          formatter: value => {
            return value === true ? "Ok" : "Ko";
          },
        },{
          key: 'auditteamcover',
          label: 'Audit team',
          formatter: value => {
            return value === true ? "Ok" : "Ko";
          },
        },{
          key: 'technicalcover',
          label: 'Veto team',
          formatter: value => {
            return value === true ? "Ok" : "Ko";
          },
        },{
          key: 'timecover',
          label: 'Disponibilità',
          formatter: value => {
            return value === true ? "Ok" : "Ko";
          },
        },{
          key: 'signature_check',
          label: 'Firma',
          formatter: value => {
            return value === true ? "Si" : "Da firmare";
          },
        }],
        costsyears: [{
          key: "year",
          label: "Anno"
        },{
          key: "standards",
          label: "Norme"
        },{
          key: 'phase',
          label: 'Fase'
        },{
          key: 'cost',
          label: 'Prezzo (€)'
        },{
          key: 'mandays',
          label: 'Giorni-uomo',
        },{
          key: 'audits_id',
          label: "Audit",
        },{
          key: "plandate",
          label: 'Pianificato',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "status",
          label: "Stato",
        },{
          key: 'expiracy',
          label: 'Scadenza',
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: 'phases',
          label: 'Fasi',
        }],
        histories: [{
          key: "standard",
          label: "Norma",
          sortable: true,
        },{
          key: "revnumber",
          label: "Revisione",
          sortable: true,
        },{
          key: 'fullname',
          label: 'Deciso da'
        },{
          key: "date",
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        },{
          key: "accreditated",
          label: "Accreditato",
          formatter: value => { return value == true ? "Si" : "No"}
        },{
          key: "contractstandardstate",
          label: "Stato",
        },{
          key: "note",
          label: "Note"
        }],
        quotessteps: [{
          key: 'year',
          label: 'Anno',
        },{
          key: 'service',
          label: 'Servizi',
        },{
          key: 'quote',
          label: 'Prezzo (€)',
        }],
        mails: [{
          key: 'folder',
          label: "Cartella",
          sortable: true
        },{
          key: "from",
          label: 'Da',
          sortable: false
        },{
          key: 'to',
          label: 'A',
          sortable: false
        },{
          key: 'subject',
          label: "Oggetto",
          sortable: false
        },{
          key: 'datetime',
          label: "Data",
          sortable: false,
          formatter: value => {
            if (value == null) return null;
            const today = new Date();
            var d = new Date(value);
            if ( d.getDate() == today.getDate() && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear()){
              return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':');
            } else {
              return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
            }
          },
        }],
        mailsCriteria: null,
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "datetime",
          label: "Aggiornamento",
          formatter: value => {
            if (value == null) return null;
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "locked",
          label: "Bloccato",
          formatter: value => {
            return value === true ? "Bloccato" : "";
          },
        },{
          key: "id",
          label: "",
        }],
        contacts: [{
          key: "company",
          label: "Azienda",
          sortable: true
        },{
          key: "contactname",
          label: "Nome",
          sortable: true
        },{
          key: "contact",
          label: "Mail",
          sortable: true
        },{
          key: "active",
          label: "Stato",
          sortable: true,
          formatter: value => {
            return value === true ? "Attivo" : "Non attivo";
          },
        },{
          key: "job",
          label: "Mansione",
          sortable: true
        },{
          key: "note",
          label: "Note",
          sortable: true
        },{
          key: "selected",
          label: "",
          sortable: true
        }],
        errors: [{
          key: "errorname",
          label: "Errore"
        },{
          key: 'date',
          label: "Data",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            var d = new Date(value);
            return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
        }]
      },
      comboapprove_result: [{
        id: true,
        val: "Approvato"
      },{
        id: false,
        val: "Non Approvato"
      },{
        id: null,
        val: "Da approvare"
      }],
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  methods: {
    rowClassStandards(item, type){
      if (!item || type !== 'row') return '';
      return "table-" + (item.contractsstandardsstatus_id === 3 ? "danger" : (item.contractsstandardsstatus_id === 2 ? "success" : (item.contractsstandardsstatus_id !== 1 ? "warning" : "")));
    },
    rowClassMails(item) {
      if ( item !== null ) return item.seen === false ? "table-primary" : (item.ping === true ? "table-success" : "");
    },
    rowClassDocuments(item) {
      return item !== null && item.datetime === null ? "table-danger" : "";
    },
    loadData(id){
      if ( this.data === null ) this.show = false;
      this.fields.mailsCriteria = null;
      this.openHttp("contracts/contract/", { id: id }).then((result) => {
        this.companies_idSupport = this.convertComboSimple( result.companies, result.contract.companies_id );
        this.languagesSupport = this.convertCombo(result.languages, result.contract.languages);
        this.data = result;
        this.show = true;
        this.loadErrors();
      })
    },
    loadErrors(){
      this.openHttp("contracts/contract/errors", { id: this.data.contract.id }).then((result) => {
        this.errors = result;
      })
    },
    asyncCompanies ( query ) {
      if (query.length > 5){
        this.openHttp("contracts/contract/companies", { search: query }).then((result) => {
          for ( let item of result ) {
            if ( this.data.companies.findIndex( x => x.id === item.id ) === -1 ) this.data.companies.push(item);
          }
          this.data.companies = this.data.companies.sort((a,b) => ( a.val > b.val ) ? 1 : 0 ); 
        })
      }
    },
    prepareSteps(){
      this.saveHttp("contracts/contract/stepsprepare", { id: this.data.contract.id }).then((result) => {
        if (result !== null) this.loadData(this.data.contract.id);
      })
    },
    calculateMandays(){
      this.saveHttp("contracts/mandays/", { id: this.data.contract.id }).then((result) => {
        if (result !== null) this.loadData(this.data.contract.id);
      })
    },
    prepareAudits(){
      this.saveHttp("contracts/contract/auditsprepare", { id: this.data.contract.id }).then((result) => {
        if (result !== null) this.loadData(this.data.contract.id);
      })
    },
    deadlineFix(){
      this.saveHttp("contracts/contract/deadlinefix", { id: this.data.contract.id }).then((result) => {
        if (result !== null) this.loadData(this.data.contract.id);
      })
    },
    prepareMail(){
      this.showMail = false;
      let contactsTos = [];
      let contactsNames = [];
      for (let item of this.data.contacts) {
        if ( item.selected === true ){
          contactsTos.push(item.contact);
          if (contactsNames.includes(item.contactname) === false) contactsNames.push(item.contactname);
        }
      }
      this.$refs.mailnew.loadData({
        id: 0,
        mailboxes_id: null,
        to: contactsTos.join(","),
        cc: "",
        bcc: "",
        subject: 'Contratto documenti',
        body: "",
        contracts_id: this.data.contract.id,
        templates_id: null,
        attachments: this.data.documents.map(x => { return { filename: x.filename, id: x.id } }),
        params: {
          company: this.data.companies.find(x => x.id == this.data.contract.companies_id).company,          
          standards: this.data.standards.map(x => x.standard).join(", "),
          fullname: contactsNames.join(", "),
        }
      });
    },
    saveData(){
      this.saveHttp("contracts/contract/save", this.data.contract).then((result) => {
        if (result !== null) this.loadData(result);
      })
    },
    duplicateData(){
      this.saveHttp("contracts/contract/duplicate", { id: this.data.contract.id }).then((result) => {
        if (result !== null) this.loadData(result);
      })
    },
    deleteData(){
      this.deleteHttp("contracts/contract/delete", { id: this.data.contract.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit('update');
    },
  },
}
</script>