<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Esperienza - ' + data.experience.id" @hidden="closeForm()" @close="closeForm()">
      <curriculumdocument ref="document" @update="loadData(data.experience.users_id, data.experience.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Form" active>
            <b-row>
              <b-col lg="4">
                <b-form-group label="Inizio">
                  <b-form-input v-model="data.experience.fromdate" type="date"/>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label="Fine (lasciare vuoto se in Corso)">
                  <b-form-input v-model="data.experience.todate" type="date"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Azienda">
              <b-form-input v-model="data.experience.company"/>
            </b-form-group>
            <b-form-group label="Settore">
              <b-input-group v-for="item of data.experience.languages" :key="item.id" :prepend="data.languages.find(x => x.id === item.id).val" class="mb-1">
                <b-form-textarea rows="2" max-rows="10" v-model="item.sector"/>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Aree">
              <multiselect v-model="standardsareasSupport" :options="data.standardsareas" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.experience.standardsareas = updateCombo(standardsareasSupport)"/>
            </b-form-group>
            <b-form-group label="Ruolo e Responsabilità">
              <b-input-group v-for="item of data.experience.languages" :key="item.id" :prepend="data.languages.find(x => x.id === item.id).val" class="mb-1">
                <b-form-textarea v-model="item.responsibilities" rows="2" max-rows="10"/>
              </b-input-group>
            </b-form-group>
            <b-form-group label="IAFs">
              <multiselect v-model="nacesiafcodesSupport" :options="data.nacesiafcodes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.experience.nacesiafcodes = updateCombo(nacesiafcodesSupport)"/>
            </b-form-group>
          </b-tab>
          <b-tab :title="'Documents - ' + data.documents.length"  v-if="data.experience.id > 0">
            <b-btn variant="primary" size="sm" @click="loadDocument(0)">Nuovo</b-btn>
            <b-table no-border-collapse :sticky-header="(winHeight - 380) + 'px'" responsive class="mt-3" :items="data.documents" :fields="fields.documents" @row-clicked="loadDocument($event.id)">
              <template v-slot:cell(id)="row">
                <b-btn variant="primary" size="sm" @click="$refs.document.downloadData(row.item.id)">Scarica</b-btn>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" v-show="data.experience.id > 0" class="mr-2" @click="loadData(data.experience.users_id, data.experience.id)">Aggiorna</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="data.experience.id > 0" @click="deleteData">Elimina</b-btn>
          <b-btn variant="warning" v-show="data.experience.id > 0" @click="migrateData">Migrate</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import curriculumdocument from './curriculumdocument.vue';

export default {
  name: "curriculumexperience",
  components: { curriculumdocument },
  data() {
    return {
      show: false,
      data: null,
      standardsareasSupport: [],
      nacesiafcodesSupport: [],
      fields: {
        documents: [{
          key: "filename",
          label: "File",
          sortable: true,
        },{
          key: "datetime",
          label: "Aggiornamento",
          formatter: value => {
            let d = new Date(value);
            return [ d.getHours(), d.getMinutes() ].map(n => n < 10 ? `0${n}` : `${n}`).join(':') + " " + [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
          },
          sortable: true,
        },{
          key: "locked",
          label: "Bloccato",
          formatter: value => {
            return value === true ? "Bloccato" : "";
          },
        },{
          key: "id",
          label: "",
        }]
      }
    }
  },
  computed: {
    winHeight(){
      return this.$store.getters.getWinHeight;
    },
  },
  methods: {
    rowClassDocuments(item) {
      return item !== null && item.check === false ? 'table-danger' : '';
    },
    loadData( usersId, id ){
      this.openHttp("qualifications/curriculum/experience/", { users_id: usersId, id: id }).then((result) => {
        this.nacesiafcodesSupport = this.convertCombo(result.nacesiafcodes, result.experience.nacesiafcodes);
        this.data = result;
        this.show = true;
      })
    },
    loadDocument(fileName){
      this.$refs.document.loadData("experiences", this.data.experience.id, fileName);
    },
    saveData(){
      this.saveHttp("qualifications/curriculum/experience/save", this.data.experience).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("qualifications/curriculum/experience/delete", { id: this.data.experience.id }).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    migrateData(){
      this.deleteHttp("qualifications/curriculum/experience/consultancy", { id: this.data.experience.id }).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    closeForm( reload = false ){
      this.show = false;
      this.data = null;
      if ( reload === true ) this.$emit('update');
    }
  }
}
</script>