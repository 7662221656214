<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" size="lg" title="NACE" scrollable @hidden="closeForm" @close="closeForm">
            <b-row>
                <b-col lg="2">
                    <b-form-group label="NACE completo">
                        <b-form-input v-model="data.nace.nacecomplete"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Ordine">
                        <b-form-input type="number" v-model="data.nace.ordernace"/>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group label="Famiglia">
                        <b-form-select v-model="data.nace.nacessupers_id" :options="data.nacessupers" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
                <b-col lg="2">
                    <b-form-group label="Cantiere">
                        <b-form-select v-model="data.nace.yardnec" :options="yardnec" value-field="id" text-field="val"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-form-group label="Descrizione">
                <b-input-group :prepend="item.lang" v-for="(item, index) in data.nace.languages" :label="'Descrizione ' + item.lang" :key="index">
                    <b-form-input v-model="item.description"/>
                </b-input-group>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
                    <b-btn variant="danger" v-show="data.nace.id> 0" @click="deleteData">Elimina</b-btn>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "nace",
    data() {
        return {
            show: false,
            data: null,
            yardnec: [{
                id: false,
                val: 'Non necessario',
            },{
                id: true,
                val: 'Necessario',
            }]
        }
    },
    methods: {
        loadData(id){
            this.openHttp("standards/nace", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        saveData(){
            this.saveHttp("standards/nace/save", this.data.nace).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        deleteData(){
            this.deleteHttp("standards/nace/delete", { id: this.data.nace.id }).then((result) => {
                if ( result !== null ) this.closeForm();
            })
        },
        closeForm(){
            this.show = false;
            this.data = null;
            this.$emit('update');
        },
    },
}
</script>