<template>
  <div>
    <bank ref="bank" @update="loadData"/>
    <b-btn class="mr-2" variant="primary" @click="loadData">Aggiorna</b-btn>
    <b-btn class="mr-2" variant="success" @click="loadbank(null)">Nuovo</b-btn>
    <b-table no-border-collapse :sticky-header="(winHeight - 170) + 'px'" responsive show-empty mode="remote" class="mt-3" :items="items" :fields="fields" :busy="isBusy" @row-clicked="loadbank($event.date)">
      <template v-slot:table-busy>
        <div class="text-center text-primary">
          <b-spinner class="align-middle"/>
        </div>
      </template>
      <template v-slot:empty>Non ci sono risultati</template>
    </b-table>
  </div>
</template>

<script>
import bank from './bank.vue';

export default {
  name: "banks",
  components: { bank },
  data() {
    return {
      fields: [{
        key: 'date',
        label: 'Data',
        sortable: true,
        formatter: value => {
          if (value == null) return null;
          var d = new Date(value);
          return [ d.getDate(), d.getMonth() + 1, d.getFullYear() ].map(n => n < 10 ? `0${n}` : `${n}`).join('-');
        },
      },{

        key: 'money',
        label: 'Importo',
      }],
      items: []
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      this.isBusy = true;
      this.openHttp("kpis/banks/", { }).then((result) => {
        this.isBusy = false;
        this.items = result;
      });
    },
    loadbank(date){
      let d = new Date();
      this.$refs.bank.loadData((date) === null ? ([d.getFullYear(), d.getMonth() + 1, d.getDate()].map(n => n < 10 ? `0${n}` : `${n}`).join('-')) : date);
    }
  }
}
</script>