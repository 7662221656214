<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="xl" :title="'Domanda - ' + data.question.id" scrollable @hidden="closeForm()" @close="closeForm()">
      <b-row>
        <b-col lg="10">
          <b-form-group label="Domanda / Titolo">
            <b-form-input v-model="data.question.title"/>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-form-group label="Ordine">
            <b-form-input v-model="data.question.questionorder" type="number"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Testo">
        <b-form-textarea v-model="data.question.description" rows="5" max-rows="20"/>
      </b-form-group>
      <b-form-group label="Punti">
        <b-form-checkbox-group v-model="data.question.standardspoints">
          <div v-for="(item, index) of data.standardspoints" :key="index">
            <strong>{{ item.standard }}</strong>
            <br>
            <b-form-checkbox v-for="(item2, index2) of item.points" :key="index2" :value="item2.id">{{ item2.val }}</b-form-checkbox>
          </div>
        </b-form-checkbox-group>
        <b-input-group v-for="(item, index) in data.question.languages" :key="index" size="sm" class="mb-1" :prepend="item.lang">
          <b-form-input v-model="item.question"/>
        </b-input-group>
      </b-form-group>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="warning" class="mr-2" v-if="data.question.id > 0" @click="data.question.id = 0">Duplica</b-btn>
          <b-btn variant="success" class="mr-2" @click="saveData">Salva</b-btn>
          <b-btn variant="danger" v-show="data.question.id > 0" @click="deleteData">Elimina</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "internalauditquestion",
  data() {
    return {
      show: false,
      data: null
    }
  },
  methods: {
    loadData(id){
      this.openHttp("settings/audits/internalauditquestion/", { id: id }).then((result) => {
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("settings/audits/internalauditquestion/save", this.data.question).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    deleteData(){
      this.deleteHttp("settings/audits/internalauditquestion/delete", { id: this.data.question.id }).then((result) => {
        if (result !== null) this.closeForm();
      })
    },
    closeForm(){
      this.show = false;
      this.data = null;
      this.$emit("update");
    },
  },
}
</script>